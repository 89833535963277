<template>

</template>

<script>

export default {
    name: "Login",
    data () {
        return {
            timer: null,
            APP_ID: '101921069',
            loading: null,
            game_id: null
        }
    },
    mounted() {
        this.game_id = this.$route.query.gameId
        this.loading = this.$loading({
            lock: true,
            text: '正在登录中...请稍后',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        this.timer = setInterval(this.checkQQlogin, 3000)
    },
    methods: {
        checkQQlogin() {
            var that = this
            if(window.qc.Login.check()) {
                window.qc.Login.getMe(function (openId, accessToken) {
                    if(openId) {
                        // 获取用户信息
                        that.boxRequest({
                            url: "/Home/User/checkLoginByQQ",
                            method: "post",
                            data: { openId: openId, accessToken: accessToken}
                        }).then(result => {
                            if(result.data.code == 200) {
                                that.$message({
                                    type: "success",
                                    message: "登录成功！"
                                })
                                that.loading.close()
                                // 保存token
                                that.Public.setCookie('token', result.data.tokens)
                                that.$store.commit('updateToken', result.data.tokens)
                                that.$store.commit('updateUserInfo', result.data.result)
                                that.$store.commit('updateIsLogin', true)
                                if(that.game_id == 'undefined' || that.game_id == null) {
                                    that.$router.push('/')
                                } else {
                                    that.$router.push('/play?gameId='+that.game_id)
                                }
                            }
                        }).catch(error => {
                            that.$message("服务器出现异常！登录失败！");
                        })
                    }
                });
                clearInterval(this.timer)
                this.timer = null
            } else {
                this.$message("服务器出现异常！登录失败！");
            }
        }
    }
}
</script>

<style scoped>

</style>